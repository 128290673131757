<template>
  <!--配额缓存管理-->
  <div>
    <el-divider content-position="left"><h3>配额缓存管理</h3></el-divider>
    <dynamicTable
      ref="dynamicTable"
      class="myTable"
      :table-header="tableData.title"
      :table-data="tableData.data"
      :loading="loading"
      @handleClick="handleClick"
    />
  </div>
</template>

<script>
import dynamicTable from "./components/Table/v-dynamicTable";
import * as cacheApi from "./api.js";
export default {
  name: "quotaCache",
  components: {
    dynamicTable,
  },
  props:{
    // 缓存登录状态
    cachedLoginState:{
      type: String,
      default:""
    }
  },
  data() {
    return {
      loading: false,
      tableData: {
        data: [
          {
            operation: "刷新",
            message: "",
            explain: "请输入需要刷新的配额code，为空则刷新全部",
            refreshQuotaCode: "",
          },
        ],
        title: [
          {
            index: 1,
            sortable: false,
            title: "操作",
            prop: "operation",
            type: "button",
            width: 80
          },
          {
            index: 1,
            sortable: false,
            title: "",
            prop: "message",
            type: "text",
            width: 150
          },
          {
            index: 3,
            sortable: false,
            title: "说明",
            prop: "explain",
            type: "text",
          },
          {
            index: 2,
            sortable: false,
            title: "刷新配额code",
            prop: "refreshQuotaCode",
            type: "input",
          },
        ],
      },
    };
  },
  methods: {
    /**
     * 监听table操作事件  刷新
     */
    handleClick(scope) {
      scope.row.message = '正在刷新，请稍后...'
      let params = {
        // 刷新配额code
        resourceCode:scope.row.refreshQuotaCode,
        // 登录状态
        cachedLoginState:this.cachedLoginState
      }
      cacheApi.getRefreshQuota(params).then(res => {
        if (res.result) {
          scope.row.message = '刷新成功'
        } else {
          this.$message.info(res.returnInfo);
        }
      }).catch(error=>{
        this.$message.error(error.returnInfo);
      })
    }
  }
};
</script>

<style scoped>
</style>
