<template>
  <!-- v-el-table-infinite-scroll="load" -->
  <el-table
    id="el-table"
    ref="table"
    v-loading="loading"
    class="dynamicTable"
    :data="tableData"
    height="100%"
    :row-class-name="tableRowClassName"
    :cell-class-name="tableCellClassName"
    :stripe="true"
    :border="border"
    @cell-click="rowClick"
    @selection-change="handleSelectionChange"
  >
    <el-table-column
      v-if="paixu"
      :label="paixu"
      align="center"
      width="60"
      type="index"
      :index="1"
    />
    <el-table-column v-if="isMultipleSelection" type="selection" width="55" />
    <template v-for="(item, index) in tableHeader">
      <table-column
        v-if="item.childList && item.childList.length"
        :key="index"
        :coloumn-header="item"
      />

      <el-table-column
        v-else
        :key="index"
        :width="item.width"
        :label="$t(item.title)"
        :prop="item.prop"
        align="left"
      >
        <template slot-scope="scope">
          <!--渲染文本数据-->
          <span
            v-if="item.type == 'text'"
            class="myHtml"
            v-html="scope.row[item.prop]"
          />
          <!--渲染button数据-->
          <el-button
            v-if="item.type == 'button'"
            size="small"
            @click="handleClick(scope)"
          >
            <span>
              {{ scope.row[item.prop] }}
            </span>
          </el-button>
          <!--渲染input数据-->
          <el-input
            v-if="scope.row.hasOwnProperty(item.prop) && item.type == 'input'"
            v-model="scope.row[item.prop]"
          ></el-input>
        </template>
      </el-table-column>
    </template>
  </el-table>
</template>

<script>
import tableColumn from "./v-tableColumn";
export default {
  components: {
    tableColumn,
  },
  props: {
    // 排序title
    paixu: {
      type: String,
      default: null,
    },
    // 是否支持多选
    isMultipleSelection: {
      type: Boolean,
      default: false,
    },
    // 表格的数据
    tableData: {
      type: Array,
      required: true,
    },
    // 多级表头的数据
    tableHeader: {
      type: Array,
      required: true,
    },
    height: {
      type: [String, Number, undefined],
      default: "",
    },
    // 行的 className 的回调方法
    tableRowClassName: {
      type: Function,
      default: null,
    },
    // 单元格的 className 的回调方法
    tableCellClassName: {
      type: Function,
      default: () => [],
    },
    // 是否带有纵向边框
    border: {
      type: Boolean,
      default: false,
    },
    // 是否带loading
    loading: {
      type: Boolean,
      default: false,
    },
    // 是否展示操作按钮
    isShowOpration: {
      type: Boolean,
      default: false,
    },
    // 操作按钮列表
    oprationBtnList: {
      type: Array,
      default: () => [],
    },
    // 是否可以行内点击
    isClick: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      multipleSelection: [], // 多选的数据
    };
  },
  mounted() {
    this.tableGetMore();
  },
  methods: {
    /**
     * 上拉加载更多数据
     */
    tableGetMore() {
      const dom = document.querySelector(
        ".dynamicTable > .el-table__body-wrapper"
      );
      dom.addEventListener("scroll", () => {
        const scrollDistance =
          dom.scrollHeight - dom.scrollTop - dom.clientHeight - 10;
        if (scrollDistance <= 0) {
          this.$emit("loadScroll");
        }
      });
    },
    /**
     *设置背景色
     */
    // getCellStyle(type ) {
    //   // if(type === 'date'){
    //     let cellstyle = {
    //       'vertical-align': 'top'
    //     };
    //     return cellstyle
    //   // }

    // },
    /**
     * 修改排序
     */
    sortData(prop) {
      return function sort(a, b) {
        if (a[prop] == "-") {
          return -1;
        }
        return parseFloat(a[prop]) - parseFloat(b[prop]);
      };
    },
    // 滚动加载
    load() {
      this.$emit("loadScroll");
    },
    // 操作按钮
    handleClick(scope) {
      this.$emit('handleClick',scope)
    },
    // 行内点击(单)
    rowClick() {},
    // 多选事件
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>
<style  lang="scss">
.dynamicTable {
  .cell-bg {
    background-color: #000 !important;
    color: #fff;
  }
  .myHtml {
    white-space: pre-wrap;
  }
  .cell-pointer {
    cursor: pointer;
  }
  .cell-pointer:hover {
    color: #409eff;
  }
}
</style>
<style scoped>
.el-table td {
  vertical-align: top !important;
}
</style>
