<template>
  <!--堆积数据管理-->
  <div>
    <el-divider content-position="left"><h3>堆积数据管理</h3></el-divider>
    <dynamicTable
      ref="dynamicTable"
      class="myTable"
      :table-header="tableData.title"
      :table-data="tableData.data"
      :loading="loading"
      @handleClick="handleClick"
    />
  </div>
</template>

<script>
import dynamicTable from "./components/Table/v-dynamicTable";
import * as cacheApi from "./api.js";
export default {
  name: "accumulationData",
  components: {
    dynamicTable,
  },
  props:{
    // 缓存登录状态
    cachedLoginState:{
      type: String,
      default:""
    }
  },
  data() {
    return {
      loading: false,
      tableData: {
        data: [
          {
            operation: "刷新",
            message: "",
            functionFlag: "domainAddress",
            explain: "刷新domainAddress信息",
          },
          {
            operation: "清空",
            message: "",
            functionFlag: "pushMessage",
            explain: "清理超过30天的推送消息",
          },
          {
            operation: "转移",
            message: "",
            functionFlag: "importOpportunityTeamDataToShareTable",
            explain:
              "业务机会小组权限转移到共享表中。私有云升级到13版本后，点击按钮，转移数据；新开户的13版本的私有云不用转移数据；公有云不用转移数据（2022-06-22）",
          },
          {
            operation: "转移",
            message: "",
            functionFlag: "importAccountTeamDataToShareTable",
            explain:
              "客户小组权限转移到共享表中。私有云升级到13版本后，点击按钮，转移数据；新开户的13版本的私有云不用转移数据；公有云不用转移数据（2022-06-22）",
          },
        ],
        title: [
          {
            index: 1,
            sortable: false,
            title: "操作",
            prop: "operation",
            type: "button",
            width: 80
          },
          {
            index: 1,
            sortable: false,
            title: "",
            prop: "message",
            type: "text",
            width:150
          },
          {
            index: 2,
            sortable: false,
            title: "功能标识",
            prop: "functionFlag",
            type: "text",
          },
          {
            index: 3,
            sortable: false,
            title: "说明",
            prop: "explain",
            type: "text",
          },
        ],
      },
    };
  },
  methods:{
    /**
     * 监听table操作事件  刷新 清空 转移
     */
    handleClick(scope) {
      scope.row.message = '正在刷新，请稍后...'
      let params = {
        // 堆积数据管理 domainAddress 或 pushMessage 或 importOpportunityTeamDataToShareTable 或 importAccountTeamDataToShareTable，
        service:scope.row.functionFlag,
        // 登录状态
        cachedLoginState:this.cachedLoginState
      }
      cacheApi.cacheHandle(params).then(res => {
        if (res.result) {
          scope.row.message = '刷新成功'
        } else {
          this.$message.info(res.returnInfo);
        }
      }).catch(error=>{
        this.$message.error(error.returnInfo);
      })
    }
  }
};
</script>

<style scoped>
</style>
