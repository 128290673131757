<template>
  <!--运维设置界面  包括 1.缓存管理 2.堆积数据管理 3.配额管理 4.分库缓存管理 5.系统配置-->
  <div class="wrapper">
    <!--缓存管理-->
    <cache-management
      class="mt50"
      v-if="cachedLoginState.length > 0"
      :cachedLoginState="cachedLoginState"
    ></cache-management>
    <!--堆积数据管理-->
    <accumulation-data
      class="mt50"
      v-if="cachedLoginState.length > 0"
      :cachedLoginState="cachedLoginState"
    ></accumulation-data>
    <!--刷新配额code-->
    <quota-cache
      class="mt50"
      v-if="cachedLoginState.length > 0"
      :cachedLoginState="cachedLoginState"
    ></quota-cache>
    <!--分库缓存管理-->
    <depots-cache
      class="mt50"
      v-if="cachedLoginState.length > 0"
      :cachedLoginState="cachedLoginState"
    ></depots-cache>
    <!--系统配置-->
    <system-configuration
      class="mt50"
      :sysConfigData="sysConfig"
      v-if="Object.keys(sysConfig).length > 0"
    ></system-configuration>
    <!--缓存登录弹窗-->
    <el-dialog
      title="请输入密码"
      :visible.sync="dialogVisible"
      width="30%"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :inline="true"
        :model="formData"
        ref="dynamicValidateForm"
        class="demo-form-inline"
      >
        <el-form-item
          label="密码："
          prop="password"
          :rules="[{ required: true, message: '请输入密码', trigger: 'blur' }]"
        >
          <el-input
            type="password"
            v-model="formData.password"
            autocomplete="new-password"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetForm('dynamicValidateForm')">重 输</el-button>
<!--        <el-button @click="dialogVisible = false">退 出</el-button>-->
        <el-button type="primary" @click="cachedLogin('dynamicValidateForm')"
          >登 录</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as cacheApi from "./api.js";
import cacheManagement from "./cacheManagement";
import accumulationData from "./accumulationData";
import quotaCache from "./quotaCache";
import depotsCache from "./depotsCache";
import systemConfiguration from "./systemConfiguration";
export default {
  name: "index",
  components: {
    cacheManagement,
    accumulationData,
    quotaCache,
    depotsCache,
    systemConfiguration,
  },
  data() {
    return {
      dialogVisible: false,
      // 缓存登录参数
      formData: {
        // 密码
        password: "",
      },
      // 系统设置数据回显值
      sysConfig: {},
      // 缓存登录状态
      cachedLoginState: "",
      // 防止重复点击
      flag: false,
    };
  },
  mounted() {
    document.getElementById("lightning-loading").style.display = "none";
    this.manageLogin();
  },
  methods: {
    // 获取系统配置的默认值以及是否登录成功
    manageLogin() {
      cacheApi
        .manageLogin()
        .then((res) => {
          if (res.result) {
            // 系统设置数据回显
            this.sysConfig = res.data;
            // 缓存登录状态
            this.cachedLoginState = res.data.cachedLoginState;
            if (res.data.cachedLoginState == 'true') { // 已登录状态
              this.dialogVisible = false
            } else {
              // 未登录状态 弹窗显示
              this.dialogVisible = true
            }
          } else {
            this.$message.info(res.returnInfo);
          }
        })
        .catch((error) => {
          this.$message.error(error.returnInfo);
        });
    },
    // 校验
    validateForm(formName) {
      let isValidate
      this.$refs[formName].validate((valid) => {
        if (valid) {
          isValidate = true
        } else {
          isValidate = false
        }
      });
      return isValidate
    },
    // 登录
    cachedLogin(){
      // let isTrue = this.validateForm(formName)
      let isTrue = true
      if (isTrue) {
        // 防止多次点击重复请求
        if (this.flag) {
          return;
        }
        this.flag = true;
        cacheApi.cachedLogin({password:this.formData.password}).then(res => {
          if (res.result) {
            if (res.data.cachedLoginState == "true") {
              this.dialogVisible = false
              this.cachedLoginState = res.data.cachedLoginState
            } else {
              this.$message.info('登录失败');
            }
            this.flag = false
          } else {
            this.$message.info(res.returnInfo);
            this.flag = false
          }
        }).catch((error) => {
          this.flag = false
          this.$message.error(error.returnInfo);
        });
      }
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 20px 50px;
}
::v-deep .el-form--inline .el-form-item {
  width: 100% !important;
}
::v-deep .el-form-item__content {
  width: 85% !important;
}
.mt50 {
  margin-bottom: 50px;
}
</style>
