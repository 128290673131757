<template>
  <!--    缓存管理-->
  <div>
    <el-divider content-position="left"><h3>缓存管理</h3></el-divider>
    <div style="display: flex; justify-content: center">
      <el-button @click="refreshAll"> 刷新全部 </el-button>
    </div>
    <dynamicTable
      ref="dynamicTable"
      class="myTable"
      :table-header="tableData.title"
      :table-data="tableData.data"
      :loading="loading"
      @handleClick="handleClick"
    />
  </div>
</template>

<script>
import dynamicTable from "./components/Table/v-dynamicTable";
import * as cacheApi from "./api.js";
export default {
  name: "cacheManagement",
  components: {
    dynamicTable,
  },
  props: {
    // 缓存登录状态
    cachedLoginState: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      tableData: {
        data: [
          {
            operation: "刷新",
            message: "",
            functionFlag: "platformService",
            explain: "刷新所有服务的Bean",
          },
          {
            operation: "刷新",
            message: "",
            functionFlag: "currency",
            explain: "刷新货币信息",
          },
          {
            operation: "刷新",
            message: "",
            code: "",
            functionFlag: "objectService",
            explain: "刷新对象信息",
          },
          {
            operation: "刷新",
            message: "",
            functionFlag: "schemetable",
            explain: "刷新schemetable信息",
          },
        ],
        title: [
          {
            index: 0,
            sortable: false,
            title: "操作",
            prop: "operation",
            type: "button",
            width: 80,
          },
          {
            index: 1,
            sortable: false,
            title: "",
            prop: "message",
            type: "text",
            width: 150,
          },
          {
            index: 2,
            sortable: false,
            title: "",
            prop: "code",
            type: "input",
            width: 150,
          },
          {
            index: 3,
            sortable: false,
            title: "功能标识",
            prop: "functionFlag",
            type: "text",
          },
          {
            index: 4,
            sortable: false,
            title: "说明",
            prop: "explain",
            type: "text",
          },
        ],
      },
    };
  },
  methods: {
    /**
     * 监听table操作事件  点击table每条数据进行刷新
     */
    handleClick(scope) {
      scope.row.message = "正在刷新，请稍后...";
      let code = "";
      if (scope.row.functionFlag == "schemetable") {
        code = "AllOBJ";
      } else if (scope.row.functionFlag == "objectService") {
        // 输入框有值的话传输入框的值  没有值的话传AllOBJ 表示刷新全部
        code = scope.row.code ? scope.row.code : "AllOBJ";
      } else {
        code = "";
      }
      let params = {
        // 缓存管理    platformService；或currency；或objectService；或schemetable，
        service: scope.row.functionFlag,
        code: code,
        // 登录状态
        cachedLoginState: this.cachedLoginState,
      };
      cacheApi
        .cacheHandle(params)
        .then((res) => {
          if (res.result) {
            scope.row.message = "刷新成功";
          } else {
            this.$message.info(res.returnInfo);
          }
        })
        .catch((error) => {
          this.$message.error(error.returnInfo);
        });
    },
    /**
     * 刷新全部方法封装
     */
    refreshAllFn(tableDataItem) {
      tableDataItem.message = "正在刷新，请稍后...";
      let code = "";
      if (tableDataItem.functionFlag == "schemetable") {
        code = "AllOBJ";
      } else if (tableDataItem.functionFlag == "objectService") {
        // 输入框有值的话传输入框的值  没有值的话传AllOBJ 表示刷新全部
        code = tableDataItem.code ? tableDataItem.code : "AllOBJ";
      } else {
        code = "";
      }
      let params = {
        // 缓存管理    platformService；或currency；或objectService；或schemetable，
        service: tableDataItem.functionFlag,
        code: code,
        // 登录状态
        cachedLoginState: this.cachedLoginState,
      };
      cacheApi
        .cacheHandle(params)
        .then((res) => {
          if (res.result) {
            tableDataItem.message = "刷新成功";
          } else {
            this.$message.info(res.returnInfo);
          }
        })
        .catch((error) => {
          this.$message.error(error.returnInfo);
        });
    },
    /**
     * 刷新全部
     */
    refreshAll() {
      this.tableData.data.forEach((item) => {
        this.refreshAllFn(item);
      });
    },
  },
};
</script>

<style scoped>
</style>
