import { render, staticRenderFns } from "./cacheManagement.vue?vue&type=template&id=bb2fd28e&scoped=true&"
import script from "./cacheManagement.vue?vue&type=script&lang=js&"
export * from "./cacheManagement.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb2fd28e",
  null
  
)

export default component.exports