<template>
  <!--系统配置-->
  <div>
    <el-divider content-position="left"><h3>系统配置</h3></el-divider>
    <el-table :data="tableData" style="width: 100%" stripe>
      <el-table-column fixed prop="function" label="功能">
      </el-table-column>
      <el-table-column prop="enabled" label="启用状态">
        <template slot-scope="scope">
          <el-input
            v-if="scope.row.type == 'csrfUrl'"
            type="textarea"
            :rows="2"
            v-model="scope.row.enabled"
            @change="change(scope)"
          >
          </el-input>
          <el-checkbox v-else v-model="scope.row.enabled" @change="change(scope)"></el-checkbox>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import * as cacheApi from "./api.js";
export default {
  name: "accumulationData",
  props:{
    sysConfigData:{
      type: Object,
      default:()=>{}
    }
  },
  mounted() {
    this.sysConfigParams = this.sysConfigData
  },
  data() {
    return {
      loading: false,
      sysConfigParams:{},
      tableData: [
        {
          function: "权限查询拼接模式:join",
          enabled: this.sysConfigData?.shareSqlQueryMode == 'join',
          type: "shareSqlQueryMode",
        },
        {
          function: "报表查询启用表直接关联",
          enabled: this.sysConfigData?.reportQueryMode == 'rds',
          type: "reportQueryMode",
        },
        {
          function: "启用jersey接口debugger调试",
          enabled: this.sysConfigData?.isdebugger == 'true',
          type: "isdebugger",
        },
        {
          function: "是否开启审批队列(手动选择审批人状态下无法使用)",
          enabled: this.sysConfigData?.isOpenApprovalQueue == 'true',
          type: "isOpenApprovalQueue",
        },
        {
          function: "是否启用csrf防护",
          enabled: this.sysConfigData?.iscsrf == 'true',
          type: "iscsrf",
        },
        {
          function: "是否启用私有标签",
          enabled: this.sysConfigData?.isprivatetag == 'true',
          type:'isprivatetag'
        },
        {
          function: "请输入防跨域需要过滤的域名用';'隔开",
          enabled: this.sysConfigData?.csrfUrl,
          type: "csrfUrl",
        },
      ],
    };
  },
  methods:{
    change(scope) {
      let params = {
        // 登录状态
        cachedLoginState:this.cachedLoginState
      }
      // 参数处理
      if(scope.row.type == 'shareSqlQueryMode') {
        // 权限查询拼接模式
        params.shareSqlQueryMode = scope.row.enabled == true ? 'join' : ""
      } else if (scope.row.type == 'reportQueryMode') {
        // 报表查询启用表直接关联    选中：rds
        params.reportQueryMode = scope.row.enabled == true ? 'rds' : ""
      } else if (scope.row.type == 'csrfUrl') {
        // 请输入防跨域需要过滤的域名用";"隔开
        params.csrfUrl = scope.row.enabled
      } else {
        params[scope.row.type] = scope.row.enabled == true ? 'true' : "false"
      }
      // 接口调用
      cacheApi.getUpdateOrgSetupPage(params).then(res => {
        if (res.result) {
          this.$message.success('修改成功');
        } else {
          this.$message.info(res.returnInfo);
        }
      }).catch(error=>{
        this.$message.error(error.returnInfo);
      })
    }
  }
};
</script>

<style scoped>
</style>
