import axios from '@/config/httpConfig'

// 进入管理登录
export function manageLogin() {
    return axios.post(window.Glod['ccex-setup'] + '/api/cachedRefresh/manageLogin')
}
// 登录
export function cachedLogin(data) {
    return axios.post(window.Glod['ccex-setup'] + '/api/cachedRefresh/cachedLogin', data)
}
// 配额缓存管理
export function getRefreshQuota(data) {
    return axios.post(window.Glod['ccex-setup'] + '/api/cachedRefresh/getRefreshQuota', data)
}
// 系统配置
export function getUpdateOrgSetupPage(data) {
    return axios.post(window.Glod['ccex-setup'] + '/api/cachedRefresh/getUpdateOrgSetupPage',data)
}
// 刷新或清除缓存数据
export function cacheHandle(data) {
    return axios.post(window.Glod['ccex-setup'] +'/api/cachedRefresh/refresh',data)
}
// 刷新对象视图
export function viewRefresh(data) {
    return axios.post(window.Glod['ccex-setup'] +'/api/cachedRefresh/viewRefresh',data)
}
